
<template>
  <v-container>
    <v-alert text prominent type="error" icon="mdi-cloud-alert" v-if="alertError!=''">
      {{alertError}}
    </v-alert>
    <v-alert text prominent type="success" icon="mdi-cloud-alert" v-if="alertSuccess!=''">
      {{alertSuccess}}
    </v-alert>
    <v-card outlined class="pa-5 mb-2">
      <v-row>
        <v-col xs="6" sm="6" md="6" lg="3">
          <v-row>
            <v-col cols="5 my-lg-auto text-center">
              <p class="text-h6 mb-1">
                <v-icon>mdi-account-supervisor-circle</v-icon>Country
              </p>
            </v-col>
            <v-col cols="7">
              <v-select
                v-model="country"
                outlined
                :items="countries"
                item-text="name"
                menu-props="auto"
                label="Select Country"
                hide-details
                return-object
                single-line
                @change="SelectCountry"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="6" sm="6" md="6" lg="3">
          <v-row>
            <v-col cols="5 my-lg-auto text-center">
              <p class="text-h6 mb-1">
                <v-icon>mdi-account-supervisor-circle</v-icon>State
              </p>
            </v-col>
            <v-col cols="7">
              <v-select
                v-model="state"
                outlined
                :items="states"
                item-text="name"
                menu-props="auto"
                label="Select State"
                hide-details
                return-object
                single-line
                @change="SelectState"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="6" sm="6" md="6" lg="3">
          <v-row>
            <v-col cols="5 my-lg-auto text-center">
              <p class="text-h6 mb-1">
                <v-icon>mdi-account-supervisor-circle</v-icon>School Districts
              </p>
            </v-col>
            <v-col cols="7">
              <v-select
                v-model="schoolDistrict"
                outlined
                :items="schoolDistricts"
                item-text="name"
                menu-props="auto"
                label="Select Districts"
                hide-details
                return-object
                single-line
                @change="SelectSchoolDistrict"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col xs="6" sm="6" md="6" lg="3">
          <v-row>
            <v-col cols="5 my-lg-auto text-center">
              <p class="text-h6 mb-1">
                <v-icon>mdi-account-supervisor-circle</v-icon>Schools
              </p>
            </v-col>
            <v-col cols="7">
              <!-- <v-select
                v-model="school"
                outlined
                :items="schoolsList"
                item-text="name"
                menu-props="auto"
                label="Select School"
                hide-details
                return-object
                single-line
                @change="ChooseSchool"
              ></v-select> -->
              <v-autocomplete
                  :items="schoolsList" item-text="name" class="mx-2" return-object label="School" solo
                  v-model="school" @change="ChooseSchool"
                ></v-autocomplete>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <template>
      <v-data-table :headers="tableHeader" :items="ListAdmins" sort-by="Name" :item-class="itemDisable" class="elevation-1" :items-per-page="5">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Manage Schools Admin</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
                  <v-btn color="primary" dark class="mb-2" @click="CreateNewSchoolAdmin">
                    New School Admin
                  </v-btn>
               
            <v-dialog v-model="dialogDisableAdmin" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to disable?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDisableAdmin">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="disableUserConfirmAdmin">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEnableAdmin" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to enable?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeEnableAdmin">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="enableUserConfirmAdmin">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editUserAdmin(item)" v-if="item.Enabled">
            mdi-pencil
          </v-icon>
          <v-icon small @click="disableUserAdmin(item)" v-if="item.Enabled">
            mdi-account-off
          </v-icon>
          <v-tooltip top v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="ml-4" color="green accent-4" v-bind="attrs" v-on="on" @click="enableUserAdmin(item)" >
                mdi-account-check
              </v-icon>
            </template>
            <span>You can enable the user here.</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
    <v-divider class="my-3"></v-divider>
    <v-row>
      <v-col>
        <template>
      <v-data-table :headers="tableHeaderTeacher" :items="ListTeachers" sort-by="Name" :item-class="itemDisable" class="elevation-1" :items-per-page="5">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Manage Teachers</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark class="mb-2" @click="CreateNewTeacher">
              New Teacher
            </v-btn>
            <v-dialog v-model="dialogDisableTeacher" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to disable?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDisableTeacher">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="disableUserConfirmTeacher">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogEnableTeacher" max-width="500px">
              <v-card>
                <v-card-title class="headline">Are you sure you want to enable?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeEnableTeacher">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="enableUserConfirmTeacher">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.LicenseExpirationDate="{ item }">
          {{ item.LicenseExpirationDate!=""?item.LicenseExpirationDate.toDate().toISOString().slice(0,10):"" }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-icon color="success" small class="mr-2" v-bind="attrs" v-on="on" @click="ReportClassProgress(item)">
            mdi-file-chart
          </v-icon> 
            </template>
            <span>Edit User</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small class="mr-2" v-bind="attrs" v-on="on"  @click="editUserTeacher(item)">
              mdi-pencil
            </v-icon>
            </template>
            <span>Edit User</span>
          </v-tooltip>
            
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="error" dark v-bind="attrs" v-on="on" class="mx-1" @click="deleteUserConfirmTeacher(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete user</span>
            </v-tooltip>
          </template>
        </v-data-table>
           </template>
        </v-col>
      </v-row>
      <v-card class="my-5" v-if="PendingUsers.length>0">
        <v-toolbar flat color="cyan lighten-5">
          <v-toolbar-title>
            Teachers created for clever but not initialized
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <template v-for="(element, index) in PendingUsers">
          <v-chip class="ma-2" color="cyan" label text-color="white">
            <v-icon left>
              mdi-account
            </v-icon>
            {{ element.email }}
            <v-icon right @click="deletePendingDialog(element.email)" color="red">
              mdi-delete-empty
            </v-icon>            
          </v-chip>
        </template>
      </v-card>

      <v-dialog v-model="dialogUserAdmin" max-width="1000px">
          <v-card>
            <v-form ref="formAdmin" v-model="valid"  @submit.prevent="CreateAdmin" lazy-validation>
              <v-card-title>
                <span class="headline">{{ formTitleAdmin }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="userAdmin.name" :rules="nameRules" label="Name" prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="userAdmin.lastName" :rules="nameRules" label="Last Name" prepend-icon="mdi-account" required></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="userAdmin.email" :rules="emailRules" prepend-icon="mdi-at" label="Email"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="userAdmin.password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="[(editedUIDAdmin==-1?passwordRules.required:passwordRules.min)]" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                   <v-card-text class="subtitle-1">Expiration Date: {{ PickerExpiration }}</v-card-text>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeAdmin">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit">
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogUserTeacher" max-width="1000px">
              <v-card>
                <v-form ref="formTeacher" v-model="valid"  @submit.prevent="CreateTeacher" lazy-validation>
                <v-card-title>
                  <span class="headline">{{ formTitleTeacher }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="userTeacher.name" :rules="nameRules" label="Name" prepend-icon="mdi-account" outlined required :readonly="(userTeacher.typeLogin != 'Email and Google' && editedUIDTeacher != -1)?true:false"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="userTeacher.lastName" :rules="nameRules" label="Last Name" prepend-icon="mdi-account" outlined required :readonly="(userTeacher.typeLogin != 'Email and Google' && editedUIDTeacher != -1)?true:false"></v-text-field>
                      </v-col>
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-select :items="['ClassLink' , 'Clever','Email and Google']" v-model="userTeacher.typeLogin" outlined label="Select Type Login" :readonly="(userTeacher.typeLogin != 'Email and Google' && editedUIDTeacher != -1)?true:false"></v-select>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field v-model="userTeacher.email" :rules="emailRules" prepend-icon="mdi-at" outlined label="Email" :readonly="(userTeacher.typeLogin != 'Email and Google' && editedUIDTeacher != -1)?true:false"></v-text-field>
                      </v-col>                   
                      <v-col cols="12" sm="6" md="6" v-if="userTeacher.typeLogin == 'Email and Google'">
                        <v-text-field v-model="userTeacher.password" outlined :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :rules="editedUIDTeacher === -1 ? [passwordRules.required] : []" :type="showPassword ? 'text' : 'password'" label="Password" counter @click:append="showPassword = !showPassword" :readonly="userTeacher.typeLogin != 'Email and Google'?true:false"></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="3" md="3" v-if="userTeacher.typeLogin=='Email and Google'">
                        <v-switch
                          v-model="userTeacher.isDemo"
                          :label="`Is Demo: ${Object.prototype.hasOwnProperty.call(userTeacher, 'isDemo')?userTeacher.isDemo.toString():false}`"
                        ></v-switch>
                      </v-col>
                      <!--<v-col cols="6" sm="3" md="3" v-if="userTeacher.typeLogin == 'Email and Google'">-->
                      <v-col cols="6" sm="3" md="3">
                        <v-switch
                          v-model="userTeacher.LeeLeeV2"
                          :label="`Aventura Espacial: ${Object.prototype.hasOwnProperty.call(userTeacher, 'LeeLeeV2')?userTeacher.LeeLeeV2.toString():false}`"
                        ></v-switch>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="userTeacher.isDemo">
                        <v-menu
                          v-model="calendarExpirationDate"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="userTeacher.licenseExpirationDate"
                              label="License Expiration Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="userTeacher.licenseExpirationDate"
                            @input="calendarExpirationDate = false"
                            :min="nowDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col> 

                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                   <v-card-text class="subtitle-1">Expiration Date: {{ userTeacher.licenseExpirationDate?userTeacher.licenseExpirationDate:PickerExpiration }}</v-card-text>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeTeacher">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text type="submit">
                    Save
                  </v-btn>
                </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
    <v-dialog v-model="dialogDeleteTeacher" max-width="500px">
      <v-card>
        <v-toolbar flat color="cyan lighten-4" dense>
          <v-toolbar-title>Seguro de eliminar:  {{ userTeacher.Name}} - {{userTeacher.Email}}?</v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteTeacher">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteTeacherConfirm">SI</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteTeacherPending" max-width="500px">
      <v-card>
        <v-toolbar flat color="cyan lighten-4" dense>
          <v-toolbar-title>Seguro de eliminar:  {{ UserTeacherClever }} ?</v-toolbar-title>
        </v-toolbar>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDeleteTeacherPending">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteTeacherConfirmPending">SI</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="dialogReportGameProgress"
        width="95%"
      >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="dialogReportGameProgress = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Game Progress</v-toolbar-title>
            <v-btn
              icon
              dark
              @click="reportPDF"
              style="position: absolute; top: 0; right: 7%; border-radius: 0; box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.2);"
              v-if="allClassRoom"
            >
              <v-icon>mdi-file-pdf</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              @click="drawer=true"
              style="position: absolute; top: 0; right: 0;"
            >
              <v-icon>mdi-information-variant</v-icon>
            </v-btn>
            <!-- <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="dialogReportGameProgress = false"
              >
                Save
              </v-btn>
            </v-toolbar-items> -->
          </v-toolbar>
        <!-- </v-card> -->
        <!-- <v-card  class="mt-2" v-for="classroom in allClassRoom"> -->

        <div id="gameProgressReport" style="padding: 0 !important; margin: 0 !important; top: 0;">            
          <template v-for="classroom in allClassRoom">
              <v-card-title>Class: {{classroom.name}} </v-card-title>
              
              <template>
                <div class="text-center">
                  <v-bottom-sheet
                    v-model="drawer"                    
                    scrollable
                  > 
                    <v-sheet class="custom-sheet">
                      <v-btn
                        class="mt-6"
                        text
                        color="error"
                        @click="drawer = !drawer"
                      >
                        close
                      </v-btn>
                      <div class="my-3 text-center">
                        <h3>Lee Lee SE Mission List</h3>
                        <v-img src="../../../assets/admin/missions.jpg"></v-img>
                      </div>
                    </v-sheet>
                  </v-bottom-sheet>
                </div>
              </template>              
              
              <v-row class="px-0">
                <v-col v-for="(student, index) in classroom.students" :key="student.id" cols="4" :class="['ma-0', 'stdBox', (index + 1) % 12 === 0 ? 'jumpPoint' : '']">
                  <v-row class="bloqueMision mx-2" style="background: rgb(239, 239, 225)">
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="4" class="col-sm-3 py-1 d-flex flex-column align-end justify-center" style="font-size: 14px;"><p><strong>{{student.name}}</strong><v-icon>mdi-arrow-right-bold</v-icon></p></v-col>
                
                        <v-col cols="4" class="col-sm-4 py-1" style="position: relative;">
                          <v-img  
                            class="ma-auto borderImage" style="filter: grayscale(1);"
                            max-height="150"
                            max-width="250"
                            :src="require('../../../assets/admin/'+missionsNames[student.mission-1].img)"
                            content-class="numeroMisionA"
                          >{{student.mission}}</v-img>
                          <div style="position: absolute; bottom: 0; right: 0;">
                            <p class="tituloMisionA" style="font-size: 12px;">{{ missionsNames[student.mission-1].name }}</p>
                          </div>
                        </v-col>                        
                        <v-col cols="4" class="col-sm-5 py-1">
                          <div class="listaMisionA">                            
                            <template v-for="(element, index) in missionsNames[student.mission-1].misiones">
                              <p :class="student.stage-1==index?'selected':''">{{element}}</p>
                            </template>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>                    
                  </v-row>                  
                </v-col>   
              </v-row>    
          </template>
        </div>
        </v-card>
      </v-dialog>
    <v-dialog v-model="dialogLoad" hide-overlay persistent width="300" style="z-index: 9999;">
      <v-card color="primary" dark>
        <v-card-text>
          Wait please
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-bottom-sheet v-model="sheet">
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          text
          color="red"
          @click="sheet = !sheet"
        >
          close
        </v-btn>
        <div class="py-3">
          This user is disabled
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-container>
</template>
<script>
import { db, functions, auth } from '../../../plugins/firebase'
import firebase from 'firebase'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
export default {
  data () {
    return {
      tableHeader: [
        { text: 'Name', value: 'Name' },
        { text: 'Last Name', value: 'LastName' },
        { text: 'Email', value: 'Email' },
        { text: 'School', value: 'School' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      tableHeaderTeacher: [
        { text: 'Name', value: 'Name' },
        { text: 'Last Name', value: 'LastName' },
        { text: 'Email', value: 'Email' },
        { text: 'Expiration date', value: 'LicenseExpirationDate' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      ListAdmins: [],
      ListTeachers: [],
      missionsNames: [
          {
            name: 'Los Sonidos',
            img: 'te-mision1.jpg',
            misiones: ['1. o-a-i-u-e', '2. m-p-s-l-t-d-r-c-ce', '3. n-f-b-j-g-ge-ch-ñ-v', '4. ll-qu-z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Las Sílabas',
            img: 'te-mision2.jpg',
            misiones: ['1. m-p-s-l-t-d', '2. r-c-ce-n-f-b-j', '3. g-ch-ñ-v-ll-qu', '4. z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Palabras',
            img: 'te-mision3.jpg',
            misiones: ['1. m-p-s-l-t-d', '2. r-c-ce-n-f-b-j', '3. g-ch-ñ-v-ll-qu', '4. z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Extensiones, sílabas inversas, sílabas con inicio',
            img: 'te-mision4.jpg',
            misiones: ['1. Extensiones', '2. Sílabas inversas', '3. Sílabas', '4. Todas las anteriores', '5. Evaluación final']
          },
          {
            name: 'Tres Sílabas',
            img: 'te-mision5.jpg',
            misiones: ['1. m-p-s-l-t-d', '2. r-c-ce-n-f-b-j', '3. g-ch-ñ-v-ll-qu', '4. z-h-y-x-k-w', '5. Evaluación final']
          },
          {
            name: 'Sílabas trabadas',
            img: 'te-mision6.jpg',
            misiones: ['1. bl-br-cl', '2. fl-fr-pl-pr', '3. gl-gr-dr-tr', '4. gue-gui-güe-güi', '5. Evaluación final']
          },
          {
            name: 'Lectura de Oraciones',
            img: 'te-mision7.png',
            misiones: ['1. Sustantivos', '2. Verbos', '3. Adjetivos', '4. Oraciones', '5. Evaluación final']
          }
        ],
      nameRules: [
        v => (v && v.length >= 2) || 'Minimum length is 2 characters',
        v => (v && v.length <= 30) || 'Name must be less than 30 characters'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 4 || 'Min 4 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
      schoolSelected: '',
      userAdmin: {
        name: '',
        lastName: '',
        email: '',
        password: '',
      },
      clearUserAdmin: {
        name: '',
        lastName: '',
        email: '',
        password: '',
      },
      userTeacher: {
        name: '',
        lastName: '',
        email: '',
        password: '',
        isDemo: false,
        LeeLeeV2:false,
        licenseExpirationDate: '',
        typeLogin:'Email and Google'
      },
      clearUserTeacher: {
        name: '',
        lastName: '',
        email: '',
        password: '',
        isDemo: false,
        LeeLeeV2: false,
        licenseExpirationDate: '',
        typeLogin:'Email and Google'
      },
      UserTeacherClever: '',
      TELeeLeeV2Prev: false,
      country: '',
      state: '',
      schoolDistrict: '',
      school: '',
      countries: [],
      states: [],
      schoolDistricts: [],
      schoolsList: [],
      schoolsTable: [],
      PendingUsers:[],
      editedUIDAdmin: -1,
      editedUIDTeacher: -1,
      dialogUserAdmin: false,
      dialogUserTeacher: false,
      dialogDisableAdmin: false,
      dialogEnableAdmin: false,
      dialogDisableTeacher: false,
      dialogEnableTeacher: false,
      dialogLoad: false,
      dialogDeleteTeacher: false,
      dialogDeleteTeacherPending: false,
      valid: true,
      PickerExpiration: '',
      calendarExpirationDate: '',
      alertError: '',
      alertSuccess: '',
      showPassword: false,
      sheet: false,
      nowDate: new Date().toISOString().slice(0,10),
      dialogReportGameProgress: false,
      allClassRoom: [],
      drawer: null,
    }
  },
  created () {
    // console.log(this.$store.state.users.user.uid)
    this.ListCountries();
  },
  methods: {
    CreateNewSchoolAdmin(){
      if (this.school == '' && this.schoolDistrict == '') {
        this.alertError = "please select country, state, school district and school";
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return
      }
      this.editedUIDAdmin = -1
      this.userAdmin = Object.assign({}, this.clearUserAdmin)
      this.dialogUserAdmin = true;
    },
    editUserAdmin (data) {
      console.log(data)
      this.editedUIDAdmin = data.UserUID;
      this.userAdmin.id = data.UserUID;
      this.userAdmin.name = data.Name;
      this.userAdmin.lastName = data.LastName;
      this.userAdmin.PickerExpiration = this.PickerExpiration;
      this.userAdmin.TimestampExpiration = this.schoolSelected.DateExpired;
      this.userAdmin.email = data.Email;
      this.userAdmin.password = "";
      // this.user = Object.assign({}, data)
      this.dialogUserAdmin = true
    },
   
    disableUserAdmin (data) {
      this.editedUIDAdmin = data.UserUID;
      this.userAdmin = Object.assign({}, data)
      this.dialogDisableAdmin = true
    },
    enableUserAdmin (data) {
      this.editedUIDAdmin = data.UserUID;
      this.userAdmin = Object.assign({}, data)
      this.dialogEnableAdmin = true
    },
    disableUserConfirmAdmin () {
      this.statusDataAdmin(this.editedUIDAdmin, false).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "disable successfully";
          let principal = this;
          setTimeout(function () { principal.alertSuccess = '' }, 3000);
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDisableAdmin()
    },
    enableUserConfirmAdmin () {
      this.statusDataAdmin(this.editedUIDAdmin, true).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "enable successfully";
          let principal = this;
          setTimeout(function () { principal.alertSuccess = '' }, 3000);
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeEnableAdmin()
    },
    closeAdmin () {
      this.dialogUserAdmin = false
      this.$refs.formAdmin.resetValidation()
      this.$nextTick(() => {
        this.userAdmin = Object.assign({}, this.clearUserAdmin)
        this.editedUIDAdmin = -1
      })
    },
    closeDisableAdmin () {
      this.dialogDisableAdmin = false
      this.$nextTick(() => {
        this.userAdmin = Object.assign({}, this.clearUserAdmin)
        this.editedUIDAdmin = -1
      })
    },
    closeEnableAdmin () {
      this.dialogEnableAdmin = false
      this.$nextTick(() => {
        this.userAdmin = Object.assign({}, this.clearUserAdmin)
        this.editedUIDAdmin = -1
      })
    },
    CreateAdmin () {
      if (!this.$refs.formAdmin.validate()) return;
      if (this.editedUIDAdmin !== -1) {
        this.dialogLoad = true;
        this.updateDataAdmin(this.userAdmin).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "se actualizo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'no se encontro el documento';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createDataAdmin(this.userAdmin).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "se creo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      this.closeAdmin()
      this.$refs.formAdmin.resetValidation()
    },

    validate () {
      this.$refs.formAdmin.validate()
    },
    itemDisable(item) {
      return !item.Enabled? 'disableUserAdmin' : ''
    },
    // llamadas a firebase
    async createDataAdmin (user) {
      let answer = { result: '', message: '' }
      try {
        let uidSuperAdmin = this.$store.state.user;
        if (uidSuperAdmin == null) {
          throw new Error('no existe el usuario');
        }

        user.table = 'SchoolAdmin';
        const setAdmin = functions.httpsCallable('createUserPrivate')
        let userCreated = await setAdmin(user)

        console.log(userCreated)
        let map = {
          Name: userCreated.data.user.displayName,
          LastName: user.lastName,
          Email: user.email
        };
        const batch = db.batch()
        let step1 = db.collection('SchoolAdmin').doc(userCreated.data.user.uid);
        let SchoolReference = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id);
        batch.update(step1, {
          "LastName": user.lastName,
          "SchoolDistrict": this.schoolDistrict.name,
          "School": this.school.name,
          "PickerExpiration": this.schoolSelected.DateExpired,
          "Reference": SchoolReference,
          "Enabled": true
        });
        let step2 = SchoolReference
        batch.update(step2, { [`SchoolAdmins.${[userCreated.data.user.uid]}`]: map });
        await batch.commit()
        answer.result = true;
        this.ChooseSchool();
      } catch (error) {
        answer.result = false;
        answer.message = error;
        // console.log(error);
      }
      return answer;
    },
    async updateDataAdmin (user) {
      console.log(user)
      let answer = { result: '', message: '' }
      try {
        user.table = 'SchoolAdmin';
        const updatetAdmin = functions.httpsCallable('updateUserPrivate')
      
        let userCreated=await updatetAdmin(user);
        console.log(userCreated)
        let map = {
            Name: userCreated.data.user.displayName,
            LastName: user.lastName,
            Email: user.email
        };
        const batch = db.batch()
        let step1 = db.collection('SchoolAdmin').doc(userCreated.data.user.uid);
        let SchoolReference = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id)
        batch.update(step1, {
          "LastName": user.lastName,
          "SchoolDistrict": this.schoolDistrict.name,
          "School": this.school.name,
          "Reference": SchoolReference
        });
        let step2 = SchoolReference
        batch.update(step2, { [`SchoolAdmins.${[userCreated.data.user.uid]}`]: map });
        await batch.commit();
        answer.result = true;
        this.ChooseSchool();
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      return answer;
    },
    async statusDataAdmin (id, status) {
      let answer = { resutl: '', message: '' }
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = 'this user is actually login';
        answer.resultado = false;
        return answer;
      }
      try {
        let statusAdmin=null;
        if (status)
          statusAdmin = functions.httpsCallable('enableSchoolAdmin');
        else
          statusAdmin = functions.httpsCallable('disableSchoolAdmin');
        await statusAdmin(id)
          .then(() => {
            answer.result = true;
          })
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },
    async ListCountries () {
      await db.collection('SchoolsData').doc('RegionSchoolD').get().then((doc) => {
        let Countries = doc.data().Countries;
        let ids = Object.keys(Countries);
        let newCountries = [];
        ids.forEach(element => {
          newCountries.push({ id: element, name: Countries[element] })
        });
        newCountries.sort((a, b) => a.name.localeCompare(b.name));
        this.countries = newCountries;
      }).catch((error) => {
        console.log(error)
      });
    },
    async SelectCountry () {
      this.dialogLoad = true;
      let doc = await db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).get();
      let ListStates = doc.data().States;
      let ids = Object.keys(ListStates);
      let newStates = [];
      ids.forEach(element => {
        newStates.push({ id: element, name: ListStates[element] })
      });
      newStates.sort((a, b) => a.name.localeCompare(b.name));   
      this.states = newStates;
      // aqui reiniciamos los demas selects
      this.schoolDistricts = [];
      this.schoolsList = [];
      this.state = '';
      this.schoolDistrict = '';
      this.school = '';
      this.dialogLoad = false;
    },
    async SelectState () {
      this.dialogLoad = true;
      let SchoolDistrictReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id);
      let doc = await SchoolDistrictReference.get();
      let ListSchoolDistrict = doc.data().SchoolDistricts;
      let ids = Object.keys(ListSchoolDistrict);
      let newSchoolDistricts = [];
      ids.forEach(element => {
        newSchoolDistricts.push({ id: element, name: ListSchoolDistrict[element] })
      });
      newSchoolDistricts.sort((a, b) => a.name.localeCompare(b.name));
      this.schoolDistricts = newSchoolDistricts;
      // aqui reiniciamos los demas selects
      this.schoolsList = [];
      this.schoolDistrict = '';
      this.school = '';
      this.dialogLoad = false;
    },
    async SelectSchoolDistrict () {
      this.dialogLoad = true;
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id);
      let doc = await SchoolReference.get()
      let ListSchool = doc.data().Schools;
      let ids = Object.keys(ListSchool);      

      this.schoolsList = [];
      if (Object.prototype.hasOwnProperty.call(doc.data(), "Schools2")) {
        const schools2List=doc.data().Schools2;
        schools2List.sort((a, b) => a.name.localeCompare(b.name));
        schools2List.forEach(idVSchool => {
          db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id).collection("Schools").doc(idVSchool.id).get().then(
            (dataSc)=>{
              if (dataSc.data().LicencesRegistration > 0) {
                this.schoolsList.push({ id: idVSchool.id, name: idVSchool.name });
              }
            }
          );
          if (ids.includes(idVSchool)) {
            ids = ids.filter(id => id != idVSchool);
          }
        });
        
        this.schoolsList.unshift({ divider: true });
        this.schoolsList.unshift({ header: 'SCHOOL LIST 2' });
        this.schoolsList.unshift({ divider: true });       
      }      
      //Lo ordenamos
      ids.sort((a, b) => ListSchool[b].localeCompare(ListSchool[a]));
      ids.forEach(element => {
        this.schoolsList.unshift({ id: element, name: ListSchool[element] })
      });     

      this.school = '';
      this.dialogLoad = false;
    },
    /*async SelectSchoolDistrict () {//Escuelas no mostradas
      this.dialogLoad = true;
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id);
      let doc = await SchoolReference.get()
      let ListSchool = doc.data().Schools2;
      const mappedObject = ListSchool.reduce((obj, item) => {
        obj[item.id] = item.name;
        return obj;
      }, {});
      let ids = Object.keys(mappedObject);
      //let ids = ListSchool.map(school => school.id);console.log(ids);
      let newSchools = [];
      ids.forEach(element => {
        newSchools.push({ id: element, name: mappedObject[element] })
      });
      this.schoolsList = newSchools;
      this.school = '';
      this.dialogLoad = false;
    },*/
    /*
    async SelectSchoolDistrict() {//Escuelas registradas lento
      this.dialogLoad = true;
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id);
      let doc = await SchoolReference.get()
      let ListSchool = doc.data().Schools2;
      const mappedObject = ListSchool.reduce((obj, item) => {
        obj[item.id] = item.name;
        return obj;
      }, {});
      let ids = Object.keys(mappedObject);
      //let ids = ListSchool.map(school => school.id);console.log(ids);
      let newSchools = [];
      this.schoolsTable=[];
      //ids.forEach(element => {
      for (const element of ids) {  
        let doc = await SchoolReference.collection('Schools').doc(element).get();        
        let _school = doc.data();
        if (_school.LicencesRegistration>0){
          this.schoolsTable.push(_school);
          newSchools.push({ id: element, name: mappedObject[element] });
        }
      }
      //});
      this.schoolsList = newSchools;
      this.school = '';
      this.dialogLoad = false;
    },
    */
    async ChooseSchool() {
      let SchoolReference = db.collection('SchoolsData').doc('RegionSchoolD').collection('Countries').doc(this.country.id).collection('States').doc(this.state.id).collection('SchoolDistricts').doc(this.schoolDistrict.id).collection('Schools').doc(this.school.id);
      let doc = await SchoolReference.get();
      this.schoolSelected = doc.data();
      this.PickerExpiration = this.schoolSelected.DateExpired.toDate().toDateString();
      // this.TimestampExpiration = doc.data().DateExpired
      //listamos school admins
      let idsAdmin = Object.keys(this.schoolSelected.SchoolAdmins);
      this.ListAdmins = [];
      for (const idAdmin of idsAdmin) {
        let userAdmin =await db.collection("SchoolAdmin").doc(idAdmin).get();
        this.ListAdmins.push(userAdmin.data());
      }

      //listamos teachers
      let idsTeacher = Object.keys(this.schoolSelected.SchoolTeachers);
      this.ListTeachers = [];
      idsTeacher.forEach(idTeacher => {
        db.collection("SchoolTeacher").doc(idTeacher).get().then((userTeacher)=>{
          let teacher=userTeacher.data();
          console.log(idTeacher +" : "+teacher);
          if (Object.prototype.hasOwnProperty.call(teacher, "IsDemo")) {
            if (!teacher.IsDemo)
              teacher.LicenseExpirationDate="";
          }
          /*else
            teacher.LicenseExpirationDate=teacher.LicenseExpirationDate.toDate().toISOString().slice(0,10);*/

          this.ListTeachers.push(teacher);
        });
      });

      //listamos los teachers pendientes
      this.PendingUsers = [];
      let idsPendingTeachers = Object.keys(this.schoolSelected.PendingUsers);
      for (const element of idsPendingTeachers) {
        this.PendingUsers.push({email:this.schoolSelected.PendingUsers[element].Email, name:this.schoolSelected.PendingUsers[element].Name })
      }
    },
    //TeacherCRUD
    editUserTeacher(teacher){
      console.log(teacher);
      this.editedUIDTeacher = teacher.UserUID;
      this.userTeacher.name = teacher.Name;
      this.userTeacher.lastName =teacher.LastName;
      this.userTeacher.email = teacher.Email;
      if (Object.prototype.hasOwnProperty.call(teacher, 'IsDemo'))
        this.userTeacher.isDemo = teacher.IsDemo;
      if (Object.prototype.hasOwnProperty.call(teacher, 'LeeLeeV2')){
        this.TELeeLeeV2Prev=teacher.LeeLeeV2;
        this.userTeacher.LeeLeeV2 = teacher.LeeLeeV2;
      }else
        this.userTeacher.LeeLeeV2 = false;
      if (teacher.LicenseExpirationDate!="")
        this.userTeacher.licenseExpirationDate = teacher.LicenseExpirationDate.toDate().toISOString().slice(0,10);
      switch (teacher.TypeLogin) {
        case 'email':
         this.userTeacher.typeLogin = "Email and Google";
          break;
        case 'clever':
          this.userTeacher.typeLogin = "Clever";
          break;
        case 'classlink':
          this.userTeacher.typeLogin = "ClassLink";
          break;
      }
      this.dialogUserTeacher = true;
    },
    CreateNewTeacher(){
      if (this.school == '' && this.schoolDistrict == '') {
        this.alertError = "please select country, state, school district and school";
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
        return
      }
      this.editedUIDTeacher = -1
      this.dialogUserTeacher = true;
    },
    CreateTeacher(){
      if (!this.$refs.formTeacher.validate()) return;
      if (this.editedUIDTeacher !== -1) {
        this.dialogLoad = true;
        this.updateDataTeacher(this.userTeacher).then(response => {
          console.log(response);
          if (response.result) {
            this.alertSuccess = "se actualizo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          } else {
            let principal = this;
            if (Object.prototype.hasOwnProperty.call(response.message, 'code')) {
              switch (response.message.code) {
                case 'not-found':
                  this.alertError = 'no se encontro el documento';
                  break;
                default:
                  this.alertError = response.message.code;
              }
            } else {
              this.alertError = response.message;
            }
            setTimeout(function () { principal.alertError = '' }, 3000);
          }
          this.dialogLoad = false
        });
      } else {
        this.dialogLoad = true;
        this.createDataTeacher(this.userTeacher).then(response => {
          console.log(response);
          if (!response.result) {
            this.alertError = response.message;
            let principal = this;
            setTimeout(function () { principal.alertError = '' }, 3000);
          } else {
            this.alertSuccess = "se creo correctamente ";
            let principal = this;
            setTimeout(function () { principal.alertSuccess = '' }, 3000);
          }
          this.dialogLoad = false
        });
      }
      this.closeTeacher()
      this.$refs.formTeacher.resetValidation()
    },
    async createDataTeacher (teacher) {
      let answer = { result: '', message: '' }
      try {
        let referenceTeacher = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id)

        if (teacher.typeLogin == 'Clever' || teacher.typeLogin == 'ClassLink') {
          //console.log(this.user.reference.path)
          let email = this.userTeacher.email;
          let existe = await this.existsUser(email);
          console.log(existe);
          // return;
          if (existe) {
            answer.result = false;
            answer.message = 'this email already exists';
            return answer;
          }

          let ReferenceGrades = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/GradeData/GradeD');
          console.log('antes de batch');
          let emailSplit = email.replace(".", "_--_");
          //console.log(emailSplit)
          var batch = db.batch();
          batch.update(referenceTeacher, { [`PendingUsers.${[emailSplit]}`]: { Email: teacher.email, Name: teacher.name, LastName: teacher.lastName }, AvailableTeacherLicences: firebase.firestore.FieldValue.increment(-1) });
          batch.set(db.collection("PreUsersClever").doc(email), { Reference: referenceTeacher, ListGradesReference: ReferenceGrades, School: this.school.name, LicenseExpirationDate: this.schoolSelected.DateExpired, Platform: teacher.typeLogin });
          await batch.commit();
          console.log('despues de batch');
          this.ChooseSchool();
          answer.result = true;
          return answer;
        }
        teacher.collection = 'SchoolTeacher';
        teacher.school = this.school.name;
        teacher.reference = referenceTeacher.path;
        teacher.dateExpiration = this.schoolSelected.DateExpired.toDate().toISOString().slice(0,10);
        let ReferenceGrades = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/GradeData/GradeD');
        teacher.ListGradesReference = ReferenceGrades.path;
        teacher.Multiplayer = false;
        if (this.schoolSelected.MultiplayerLicenses != undefined){
          if (this.schoolSelected.MultiplayerLicenses>0){
            teacher.Multiplayer = true;
          }
        }
        const setTeacher = functions.httpsCallable('createTeacher')
        await setTeacher(teacher);
        this.ChooseSchool();
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log(error);
      }
      return answer;
    },
    async updateDataTeacher (teacher) {
      console.log(teacher);
      let answer = { result: '', message: '' }
      try {
        /*if (teacher.typeLogin == 'Clever') {
          throw new Error('no puede editar con usuarios clever')
        }
        if (teacher.typeLogin == 'ClassLink') {
          throw new Error('no puede editar con usuarios ClassLink')
        }*/
        teacher.id = this.editedUIDTeacher;
        teacher.collection = 'SchoolTeacher';
        teacher.school = this.school.name;
        teacher.reference = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id).path;
        teacher.dateExpiration = this.schoolSelected.DateExpired.toDate().toISOString().slice(0,10);
        
        const updateTeacher = functions.httpsCallable('updateTeacher');
        await updateTeacher(teacher);
        if (this.TELeeLeeV2Prev!=teacher.LeeLeeV2){
          //Si hay cambios activamos en los estudiantes la version 2
          const updateTeacherClassroom = functions.httpsCallable('updateTeacherClassroom');
          await updateTeacherClassroom(teacher);//teacher.id
          this.TELeeLeeV2Prev=false;
        }
        answer.result = true;
      } catch (error) {
        answer.result = false;
        answer.message = error;
        console.log('entro al log general');
      }
      this.ChooseSchool();
      return answer;
    },
    deletePendingDialog(email){
      this.dialogDeleteTeacherPending = true;
      this.UserTeacherClever=email;
    },
    closeDeleteTeacherPending(){
      this.dialogDeleteTeacherPending = false;
      this.UserTeacherClever="";
    },
    async deleteTeacherConfirmPending() {
      this.dialogLoad = true;
      let SchoolReference = db.doc('SchoolsData/RegionSchoolD/Countries/' + this.country.id + '/States/' + this.state.id + '/SchoolDistricts/' + this.schoolDistrict.id + '/Schools/' + this.school.id);
      const deletePreTeacher = functions.httpsCallable('deletePreTeacher')
      await deletePreTeacher({email:this.UserTeacherClever, reference: SchoolReference.path})
        .then((res) => {
          this.dialogLoad = false;
          this.ChooseSchool();
          //console.log(res)
          this.dialogDeleteTeacherPending = false;
          this.UserTeacherClever="";
        })
    },
    async existsUser (email) {
      let signInMethods = await auth().fetchSignInMethodsForEmail(email);
      if (signInMethods.length == 0) {
        let docVerify = await db.collection("PreUsersClever").doc(email).get();
        if (!docVerify.exists) {
          console.log('No existe!');
          return false;
        } else {
          console.log('si existe');
          return true
        }
              
      } else {
        console.log(signInMethods)
        console.log('si existe')
        return true;
      }
    },
    disableUserTeacher (data) {
      this.editedUIDTeacher = data.id;
      this.userTeacher = Object.assign({}, data)
      this.dialogDisableTeacher = true
    },
    enableUserTeacher (data) {
      this.editedUIDTeacher = data.id;
      this.userTeacher = Object.assign({}, data)
      this.dialogEnableTeacher = true
    },
    disableUserConfirmTeacher () {
      this.statusDataTeacher(this.editedUIDTeacher, false).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "disable successfully";
          let principal = this;
          setTimeout(function () { principal.alertSuccess = '' }, 3000);
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDisableTeacher()
    },
    enableUserConfirmTeacher () {
      this.statusDataTeacher(this.editedUIDTeacher, true).then((answer) => {
        if (answer.result) {
          this.alertSuccess = "enable successfully";
          let principal = this;
          setTimeout(function () { principal.alertSuccess = '' }, 3000);
        } else {
          this.alertError = answer.message;
        }
      });
      this.closeDisableTeacher()
    },
    closeTeacher () {
      this.dialogUserTeacher = false
      this.$refs.formTeacher.resetValidation()
      this.$nextTick(() => {
        this.userTeacher = Object.assign({}, this.clearUserTeacher)
        this.editedUIDTeacher = -1
      })
    },
    closeDisableTeacher () {
      this.dialogDisableTeacher = false
      this.$nextTick(() => {
        this.userTeacher = Object.assign({}, this.clearUserTeacher)
        this.editedUIDTeacher = -1
      })
    },
    closeEnableTeacher () {
      this.dialogEnableTeacher = false
      this.$nextTick(() => {
        this.userTeacher = Object.assign({}, this.clearUserTeacher)
        this.editedUIDTeacher = -1
      })
    },
    async statusDataTeacher (id, status) {
      let answer = { resutl: '', message: '' }
      let uidLogin = this.$store.state.user.uid;
      if (uidLogin === id) {
        answer.mensaje = 'this user is actually login';
        answer.resultado = false;
        return answer;
      }
      try {
        // let statusAdmin=null;
        // if (status)
        //   statusAdmin = functions.httpsCallable('enableSchoolAdmin');
        // else
        //   statusAdmin = functions.httpsCallable('disableSchoolAdmin');
        // await statusAdmin(id)
        //   .then(() => {
        //     answer.result = true;
        //   })
      } catch (error) {
        answer.message = error;
        answer.resutl = false;
      }
      return answer;
    },
    deleteUserConfirmTeacher(teacher){
      this.editedUIDTeacher = teacher.UserUID;
      this.userTeacher = Object.assign({}, teacher)
      this.dialogDeleteTeacher = true
    },
    closeDeleteTeacher () {
      this.dialogDeleteTeacher = false
      this.$nextTick(() => {
        this.userTeacher = Object.assign({}, this.clearUserTeacher)
        this.editedUIDTeacher = -1
      })
    },
    async deleteTeacherConfirm (){
      this.dialogLoad = true;
      //verificamos que no tenga usuarios asociados 
      let Data = await db.collection("SchoolTeacher").doc(this.editedUIDTeacher).get();
      let Teacher =Data.data();
      console.log(Teacher);
      try {
        let ids = Object.keys(Teacher.Classrooms);
        ids.forEach(element => {
          console.log(Teacher.Classrooms[element]);
          if (Teacher.Classrooms[element].NumberStudents > 0) {
            throw new Error('Error: Su clase aun tiene alumnos!');
          }
        });
        let data = {id: this.editedUIDTeacher, reference: Teacher.Reference.path};
        const deleteTeacher = functions.httpsCallable('deleteTeacher');console.log(data);
        await deleteTeacher(data);

        await this.ChooseSchool();
        this.alertSuccess = "Delete Teacher successfully";
        let principal = this;
        this.dialogLoad = false;
        setTimeout(function () { principal.alertSuccess = '' }, 3000);
      } catch (error) {
        this.dialogLoad = false;
        this.alertError = error;
        let principal = this;
        setTimeout(function () { principal.alertError = '' }, 3000);
      }
      
      //eliminamos de 3: auth, schoolteacher, data
      this.closeDeleteTeacher();
    },
    async ReportClassProgress(teacher){
      this.dialogReportGameProgress = true;
      this.dialogLoad = true;
      let clases = Object.keys(teacher.Classrooms);
      if (clases.length >0) {
        this.allClassRoom=[];
        for (const clase of clases) {
          let temporallist = [];
          let ClassRoom= await db.collection('SchoolTeacher').doc(teacher.UserUID).collection("Classrooms").doc(clase).get();
          // let dataClass=ClassRoom.data().MainGroup;
          let listStudents= Object.keys(ClassRoom.data().MainGroup);
          // dataClass.forEach( async (student) => {
            
          for (const student of listStudents){
            let result = await db.collection('Students').doc(student).get();
            console.log(result.data())
            if (Object.prototype.hasOwnProperty.call(result.data(), 'Alumno')) {
              temporallist.push({ id: student, name: result.data().Name+' '+result.data().LastName, mission: result.data().Alumno.MisionActual, stage: result.data().Alumno.EtapaActual });
            }
          // });
          }
          this.allClassRoom.push({ name: teacher.Classrooms[clase].Name, students: temporallist  })
        }
      }
      this.dialogLoad= false;
    },
    reportPDF(){      
      this.PrintReport();
    },
    /*async PrintReport(){
      window.html2canvas = html2canvas;
      var doc = new jsPDF('l', 'pt', 'a4');//jsPDF('l', 'mm', [297, 210]);
      doc.html(document.getElementById("gameProgressReport"), {
        callback: function(pdf) {
          pdf.save("reportGameProgress.pdf");
        }
      });
    },*/
    scaleContent(content, scale){
      content.style.transform = `scale(${scale})`;
      content.style.transformOrigin = 'top left';
    },
    PrintReport() {
      // Obtén el elemento con el contenido a convertir
      const content = document.querySelector('#gameProgressReport');
      const titleDoc = document.createElement('h1');
      titleDoc.style.textAlign = 'center';
      titleDoc.style.fontSize = '18px';
      titleDoc.textContent = 'GAME PROGRESS';
      content.insertAdjacentElement('afterbegin', titleDoc);

      //this.scaleContent(content, 0.8);

      // Configura las opciones para html2pdf
      const options = {
          margin: [10, 10, 10, 10], // Márgenes [superior, derecho, inferior, izquierdo] en milímetros
          filename: 'reportGameProgress.pdf', // Nombre del archivo PDF a descargar
          image: {
              type: 'jpeg',
              quality: 0.98
          },
          html2canvas: {
              scale: 2, // Escala para la captura de imágenes
              scrollY: 0 
          },
          pagebreak: {mode: 'avoid-all', before: '.jumpPoint', avoid: ['.stdBox', '.v-card__title']},//{avoid: ['.stdBox','.v-card__title']},          
          jsPDF: {
              unit: 'mm', // Unidad de medida (milímetros)
              format: 'letter', // Formato de página ('a4', 'letter', 'legal', etc.)
              orientation: 'landscape' // Orientación ('portrait' o 'landscape')
          }
      };
      
      // Usa html2pdf para convertir el contenido a PDF
      html2pdf().set(options).from(content).save().then(() => {
        content.removeChild(titleDoc);
      });
    }
  },
  computed: {
    formTitleAdmin () {
      return this.editedUIDAdmin === -1 ? 'New School Admin' : 'Edit School Admin'
    },
    formTitleTeacher () {
      return this.editedUIDTeacher === -1 ? 'New Teacher' : 'Edit Teacher'
    },
  },
  watch: {
    dialogUserAdmin (val) {
      val || this.closeAdmin()
    },
    dialogUserTeacher(val) {
      val || this.closeTeacher()
    },
    dialogDisableAdmin (val) {
      val || this.closeDisableAdmin()
    },
    dialogEnableAdmin (val) {
      val || this.closeEnableAdmin()
    },
    dialogDisableTeacher (val) {
      val || this.dialogDisableTeacher()
    },
    dialogEnableTeacher (val) {
      val || this.closeEnableTeacher()
    },
    dialogLoad (val) {
      // console.log(val)
      // if (!val) return
      // console.log(this.$nuxt.isOffline);
      // setTimeout(() => (this.dialogLoad = false), 4000)
    },    
    dialogDeleteTeacher (val) {
      val || this.closeDeleteTeacher()
    },
  }
}
</script>
<style>
.disableUser td:not(td:last-child){
  text-decoration: line-through;
  color: #CFCFCF;
}
.v-subheader{
  color: black !important;
  font-weight: bold !important;
}

.tituloMision{
    border: 1px solid;
    text-align: center;
    padding: 5px;
    background-color: #6ab7bf;
    color: white;
    margin-top: 35px;
    font-size: 30px;
    border-radius: 15px;
    font-weight: 600;
  }
  .tituloMisionA{
    border: 1px solid;
    text-align: center;
    padding: 2px;
    font-size: 15px;
    background-color: rgba(106,183,191,0.7);
    color: white;
    border-radius: 15px;
  }
  .listaMision p, .listaMisionA p{
    margin-bottom: 0px;
    font-size: 18px;
    margin: 0 10px 0 5px;
    padding-left: 35px;
    position: relative;
  }
  .listaMisionA p{
    font-size: 12px;
  }
  .listaMision .selected, .listaMisionA .selected{
    border-radius: 15px;
    background-color: #6ab7bf;
    color: white;
  }
  .listaMision .selected::before{
    display: block;
    position: absolute;
    left: 0rem; 
    height: 30px;
    width: 30px;
    line-height: 30px; 
    padding-left: 10px;
    content: '>';
    background-color: white;
    color: black;
    border-radius: 50px;
  }  
  .listaMisionA .selected::before{
    display: block;
    position: absolute;
    left: 0rem; 
    height: 30px;
    width: 30px;
    line-height: 30px; 
    padding-left: 10px;
    background-color: white;
    color: black;
    border-radius: 50px;
  }
  .bloqueMision {
    background-color: #f1f2f5;
    margin-left: 15px;
    border-radius: 20px;
    box-shadow: rgba(149, 157, 165, 0.37) 6px 6px 10px;
    margin-bottom: 0px;
  }
  .tituloGrande{
    margin-top: 20px;
    font-size: 22px;
  }

  .custom-sheet{
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 300px; /* Ancho deseado del v-bottom-sheet */
    background-color: white; /* Color de fondo deseado */
    box-shadow: -4px 0 8px rgba(0, 0, 0, 0.2); /* Sombra del lado derecho */
  }

  #gameProgressReport{
    transform: scale(0.9);
    transform-origin: top left;
  }

  /*.v-application .jumpPoint{
    page-break-before: always;
  }*/
</style>